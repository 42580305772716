/* eslint-disable react/jsx-one-expression-per-line */
import React from "react"
import { css } from "@emotion/core"
import { graphql, Link } from "gatsby"
import Slider from "react-slick"
import Img from "gatsby-image"

import { fonts, mediaQueries, colors } from "../styles"
import Layout from "../components/layout"
import Footer from "../components/Footer"
import SubNav from "../components/SubNav"

const linkStyle = css`
  color: #19258f;
`
const settings = {
  arrows: false,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

export default ({ data }) => {
  return (
    <Layout title="学校介绍">
      {/* Container */}
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr 1fr;
          ${mediaQueries.phoneLarge} {
            display: block;
          }
        `}
      >
        {/* Sidebar */}
        <div
          css={css`
            background-color: rgb(217, 214, 213);
            padding-top: 300px;
          `}
        >
          <div>
            <SubNav />
            <h1
              css={css`
                font-family: ${fonts.sans};
                text-align: center;
                margin-bottom: 300px;
                ${mediaQueries.phoneLarge} {
                  margin: 0;
                  padding-bottom: 300px;
                }
                &:before {
                  content: "";
                  height: 2rem;
                  width: 2rem;
                  border-radius: 50%;
                  background-color: ${colors.darkgray};
                  position: relative;
                  top: 4px;
                  margin-right: 8px;
                  display: inline-block;
                }
              `}
            >
              学校介绍
            </h1>
          </div>
          {/* Slider */}
          <div
            css={css`
              width: 50vw;
              .slick-dots {
                bottom: -35px;
                right: 15px;
                text-align: right;
              }
              .slick-dots li button:before {
                font-size: 15px;
                color: rgb(42, 42, 42);
              }
              .slick-dots li.slick-active button:before {
                color: rgb(42, 42, 42);
              }
              ${mediaQueries.phoneLarge} {
                display: none;
              }
            `}
          >
            <Slider {...settings}>
              {data.allFile.edges.map(i => (
                <Img
                  key={i.node.base}
                  fluid={i.node.childImageSharp.fluid}
                  css={css`
                    height: 420px !important;
                    width: 50.1vw !important;
                  `}
                />
              ))}
            </Slider>
          </div>
        </div>
        {/* Main Content */}
        <div
          css={css`
            background-color: rgb(42, 42, 42);
            color: rgb(217, 217, 217);
            a {
              color: rgb(217, 217, 217);
            }
          `}
        >
          <ul
            css={css`
              display: flex;
              justify-content: space-around;
              font-size: 1.4rem;
              font-weight: 600;
              margin: 0;
              padding: 1rem 0;
              list-style: none;
              ${mediaQueries.phoneLarge} {
                display: none;
              }

              li {
                position: relative;
              }

              li:before {
                content: "";
                height: 1.4rem;
                width: 1.4rem;
                border-radius: 50%;
                border: 1px solid rgb(217, 217, 217);
                position: relative;
                top: 4px;
                margin-right: 8px;
                display: inline-block;
              }

              li.active:before {
                background-color: rgb(217, 217, 217);
              }

              ul {
                visibility: hidden;
                opacity: 0;
                position: absolute;
                padding-left: 0;
                left: 0;
                top: 21px;
                display: none;
                background: rgb(42, 42, 42);
              }

              li {
                position: relative;
              }

              li:hover > ul,
              li ul:hover {
                visibility: visible;
                opacity: 1;
                display: block;
                min-width: 250px;
                text-align: left;
                box-shadow: 0px 3px 5px -1px rgb(42, 42, 42);
              }

              li ul li {
                clear: both;
                width: 100%;
                text-align: left;
                padding: 10px;
                margin: 0;
                list-style: none;
                transition: all 0.5s ease;
              }

              li ul li:not(:first-of-type) {
                border-top: 1px solid gray;
              }

              li ui li:before {
                border-style: none;
              }

              li ul li:hover {
                transition: all 0.3s ease;
                cursor: pointer;
                color: rgb(1, 9, 90);
                background-color: white;

                &:before {
                  background-color: rgb(1, 9, 90);
                }
              }
            `}
          >
            <li>
              <Link to="/" css={linkStyle}>
                清水艺术
              </Link>
            </li>
            <li>
              <Link to="/about" css={linkStyle}>
                关于清水
              </Link>
            </li>
            <li className="active">
              <Link to="/schools" css={linkStyle}>
                学校介绍
              </Link>
              <ul>
                <li>东京五美大</li>
                <li>关西三美大</li>
                <li>国公立五艺大</li>
                <li>综合类高排名院校</li>
                <li>其他院校</li>
              </ul>
            </li>
            <li>
              课程 -{" "}
              <Link to="/class/normal" css={linkStyle}>
                学部
              </Link>
              ・
              <Link to="/class/master" css={linkStyle}>
                大学院
              </Link>
            </li>
          </ul>
          <div
            css={css`
              padding: 40px 100px;
              li {
                font-size: 1.2rem;
                font-weight: 400;
              }
              ${mediaQueries.phoneLarge} {
                padding: 40px 60px;
                ul {
                  padding-left: 0;
                }
                li {
                  margin-bottom: 0.4rem;
                  padding-left: 0;
                  list-style: none;
                }
                li:before {
                  display: inline-block;
                  content: "";
                  background-image: url("/images/link.svg");
                  background-size: 18px 18px;
                  width: 18px;
                  height: 18px;
                  margin-right: 0.5rem;
                }
              }
            `}
          >
            <h2
              css={css`
                font-family: ${fonts.sans};
                margin-top: 46px;
              `}
            >
              东京五美大
            </h2>
            <ul>
              <li>
                <a href="http://www.tamabi.ac.jp/index_j.htm">多摩美术大学</a>
              </li>
              <li>
                <a href="https://www.musabi.ac.jp/">武藏野美术大学</a>
              </li>
              <li>
                <a href="https://www.zokei.ac.jp/">东京造形大学</a>
              </li>
              <li>
                <a href="http://www.art.nihon-u.ac.jp/">日本大学艺术学部</a>
              </li>
              <li>
                <a href="https://www.joshibi.ac.jp/">女子美术大学</a>
              </li>
            </ul>
            <h2
              css={css`
                font-family: ${fonts.sans};
                margin-top: 46px;
              `}
            >
              关西三美大
            </h2>
            <ul>
              <li>
                <a href="http://www.kyoto-seika.ac.jp/">京都精华大学</a>
              </li>
              <li>
                <a href="https://www.kyoto-art.ac.jp/">京都造形艺术大学</a>
              </li>
              <li>
                <a href="https://www.osaka-geidai.ac.jp/">大阪艺术大学</a>
              </li>
            </ul>
            <h2
              css={css`
                font-family: ${fonts.sans};
                margin-top: 46px;
              `}
            >
              国公立五艺大
            </h2>
            <ul>
              <li>
                <a href="https://www.geidai.ac.jp/">东京艺术大学</a>
              </li>
              <li>
                <a href="http://www.okigei.ac.jp/">冲绳县立艺术大学</a>
              </li>
              <li>
                <a href="https://www.aichi-fam-u.ac.jp/">爱知县立艺术大学</a>
              </li>
              <li>
                <a href="https://www.kanazawa-bidai.ac.jp/">金泽美术工艺大学</a>
              </li>
              <li>
                <a href="https://www.kcua.ac.jp/">京都市立艺术大学</a>
              </li>
            </ul>
            <h2
              css={css`
                font-family: ${fonts.sans};
                margin-top: 46px;
              `}
            >
              综合类高排名院校
            </h2>
            <ul>
              <li>
                <a href="http://www.tsukuba.ac.jp/">筑波大学</a>
              </li>
              <li>
                <a href="https://www.kyushu-u.ac.jp/">九州大学</a>
              </li>
              <li>
                <a href="http://www.chiba-u.ac.jp/">千叶大学</a>
              </li>
              <br />
              <li>
                <a href="https://www.t-kougei.ac.jp/">东京工艺大学</a>
              </li>
              <li>
                <a href="http://www.u-gakugei.ac.jp/">东京学艺大学</a>
              </li>
              <li>
                <a href="https://www.takara-univ.ac.jp/">宝塚大学</a>
              </li>
              <li>
                <a href="https://www.nuas.ac.jp/">名古屋学艺大学</a>
              </li>
              <li>
                <a href="https://www.nzu.ac.jp/">名古屋造形大学</a>
              </li>
              <li>
                <a href="http://www.nua.ac.jp/">名古屋艺术大学</a>
              </li>
              <li>
                <a href="https://www.oka-pu.ac.jp/">冈山县立大学</a>
              </li>
              <li>
                <a href="https://bwu.bunka.ac.jp/">文化学圆大学</a>
              </li>
              <li>
                <a href="https://bfgu-bunka.ac.jp/">文化Fashion大学院大学</a>
              </li>
              <li>
                <a href="https://www.nagaoka-id.ac.jp/">长冈造形大学</a>
              </li>
              <li>
                <a href="https://www.tuad.ac.jp/">东北艺术工科大学</a>
              </li>
              <li>
                <a href="https://www.kobe-du.ac.jp/">神户艺术工科大学</a>
              </li>
              <li>
                <a href="https://www.iamas.ac.jp/">情报科学艺术大学院大学</a>
              </li>
              <li>
                <a href="http://www.bunsei.ac.jp/">文星艺术大学</a>
              </li>
              <li>
                <a href="https://www.kyoto-saga.ac.jp/">嵯峨美术大学</a>
              </li>
              <li>
                <a href="https://www.kait.jp/">神奈川工科大学</a>
              </li>
              <li>
                <a href="https://www.dhw.ac.jp/cn/">数字好莱坞大学</a>
              </li>
              <li>
                <a href="https://www.eiga.ac.jp/index.html">日本映画大学</a>
              </li>
              <li>
                <a href="https://www.kit.ac.jp/">京都工艺纤维大学</a>
              </li>
              <li>
                <a href="https://www.yokohama-art.ac.jp/">横滨美术大学</a>
              </li>
              <li>
                <a href="https://www.akibi.ac.jp/">秋田公立美术大学</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "schools" } }) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
